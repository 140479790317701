<script setup lang="ts">
import QrcodeReaderVue3 from 'qrcode-reader-vue3'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { PersonalApi } from '~/net/apis/personal_api'
import { getBrowserFinger } from '~/utils/finger'

const router = useRouter()
const onClickLeft = () => history.back()
const qrContainer = ref(null)
const qrResult = ref(null)
const imageName = ref()
const imageUrl = ref()
const qrcodeReaderRef = ref<QrcodeReaderVue3 | null>(null)

function onDecode(result:any) {
  qrResult.value = result
  if (qrResult.value) {
    login(qrResult.value)
  }
}

function handleError(error: Error) {
  console.error('QR Error:', error)
}

function openPhotoAlbum(event: any) {
  // Logic to open photo album
  const file = event.target.files[0]
  if (file) {
    imageName.value = file.name
    const reader = new FileReader()
    reader.onload = (e) => {
      if (!e.target)
        return
      imageUrl.value = e.target.result
      console.log('imageUrl', imageUrl.value)
    }
    reader.readAsDataURL(file)
  }
}

function scannerDoneForPhotoQRCode() {

}

async function login(url) {
  const finger = await getBrowserFinger()
  const data = await PersonalApi.certificateLogin({
    certificate: url,
    deviceNo: finger
  })
  if (data) {
    router.push('/mine')
  }
}

onMounted(() => {
  // 在组件加载后可以调用 start 方法开始扫描
  if (qrcodeReaderRef.value) {
    console.log('rcodeReaderRef.value', qrcodeReaderRef.value)
    // qrcodeReaderRef.value.start()
  }
})

onBeforeUnmount(() => {
  // 在组件销毁前调用 stop 方法停止扫描
  if (qrcodeReaderRef.value) {
    qrcodeReaderRef.value.stop()
  }
})
</script>

  <template>
    <div>
      <van-nav-bar title="身份卡找回" left-arrow @click-left="onClickLeft" />
      <div class="qr-view">
        <div ref="qrContainer" class="qr-container" />
        <QrcodeReaderVue3 ref="qrcodeReaderRef" @decode="onDecode" />
        <p v-if="qrResult">Scanned Result: {{ qrResult }}</p>
        <div class="bottom-content">
          <p ct-e8e8e8>将二维码放入框内，自动扫描</p>
          <div class="gap-170" />
          <button class="photo-button">
            <!-- @click="openPhotoAlbum" -->
            <img src="~/assets/image/icon/icon_photo.png" alt="photo icon" />
            <span ct-e8e8e8>从相册选择</span>
            <!-- <input type="file" accept="image/*" @change="openPhotoAlbum" /> -->
          </button>
          <div class="gap-100" />
        </div>
      </div>
    </div>
  </template>

  <style scoped>
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .app-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .qr-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 50px; /* Adjust as needed */
  }

  .qr-container {
    width: 220px; /* Adjust size of QR scanner */
    height: 220px; /* Adjust size of QR scanner */
    border: 2px solid white;
  }

  .bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 50px; /* Adjust spacing */
  }

  .gap {
    width: 15px;
  }
  .gap-170 {
    height: 170px;
  }
  .gap-100 {
    height: 100px;
  }

  .photo-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .photo-button img {
    width: 30px; /* Adjust icon size */
    height: auto; /* Adjust icon size */
  }
  </style>
